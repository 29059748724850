import React from "react"
import Spinner from "react-bootstrap/Spinner"
import { Helmet } from "react-helmet"

const LiveControlSpinner = () => {
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <section
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Spinner animation="border" variant="primary">
          <span
            style={{
              width: "0.1px",
              height: "0.1px",
              overflow: "hidden",
              display: "block",
              margin: "0",
              padding: "0",
            }}
          >
            Loading...
          </span>
        </Spinner>
      </section>
    </>
  )
}

export default LiveControlSpinner

import React, { useState } from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import RemoveHTML from "../../components/removeHtml"
import Layout from "../../components/layout"
import Seo from "./../../components/seo"
import { Tabbordion, TabPanel, TabLabel, TabContent } from "react-tabbordion"
import useLiveControl from "./../../controlled/useLiveControl"
import LiveControlSpinner from "../../components/liveControlSpinner"

// accordian
const blockElements = {
    animator: "accordion-animator",
    content: "accordion-content",
    panel: "accordion-panel",
    label: "accordion-title",
  }

const RegionsContractorPage = ( ) => {

   const { isStaging, runningChecks } = useLiveControl()

  if (runningChecks || !isStaging) return <LiveControlSpinner />

    return (
    <Layout>
        <Seo
        title={"About Regions Home Improvement Financing"}
        description={"As of the date of the merger, Regions is a party to EnerBank customer forms, disclosures, documents, and agreements that name EnerBank as a party, and those forms, disclosures, documents, and agreements should be read with Regions substituted for EnerBank."}
        />
        <Helmet>
        <body id="rebrand" className="page-regions-contractor" />
        </Helmet>
        <section className="banner-area" id="regions-page-banner-section" >
            <div className="container banner-container"
                style={{
                    backgroundImage:
                    "url(" +
                    "http://gbdevpress.enerbank.com/wp-content/uploads/2024/05/regions-contractor-page-hero.jpg" +
                    ")",
                }}
            >
        <div className="relative banner-area-text-container">
          <div className="container text-center">
            <div class="identifier-container row-fluid">
              <div class="element span12">
                  <div class="rhif-identifier span5">
                      <h4>HOME IMPROVEMENT FINANCING</h4>
                  </div>
                  <div class="right-angle span2"></div>
              </div>
            </div>
            <h1 className="text-white text-left desktop" style={{ padding: "80 0" }}> 
            You still know us — we still know you
            </h1>
          </div>
        </div>
    </div>
    <div className="container text-center mobile">
        <h1 className="text-white text-left" style={{ padding: "80 0" }}>
            You still know us — we still know you
        </h1>
  </div>
</section>
      <section className="paragraph-text-block">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <p>For over two decades, EnerBank USA has been helping contractors offer financing to their home improvement customers — getting them the funds they needs for HVAC, roofing, siding, painting, window+door, kitchen and bath remodel, outdoor living, and more. </p>
            </div>
          </div>
        </div>
      </section>
      <section className="benefits bg-light-gray">
        <div className="container rds-grid__container">
          <div className="row">
            <div className="col-12 pb-3">    
                <h2 className="text-center mb-4">The right payment options makes all the difference</h2>
                <p className="text-center">Adding payment options as a part of your services helps you grow your home improvement business by:</p>
            </div>
          </div>
          <div className="icon-cards row justify-content-center">
            <div className="col-lg-2">
                <div class="card">
                    <div class="card-image">
                        <img src="http://gbdevpress.enerbank.com/wp-content/uploads/2024/05/Increase-leads_1.png" class="" alt="Increasing quality leads" />
                    </div>
                    <div class="card-body">
                        <h3>Increasing Quality Leads</h3>
                        {/* <p class="card-text">
                            Time is of the essence when it comes to an HVAC project.
                            With EnerBank, your customer can receive a lending decision
                            within minutes and only need to sign once upfront. This
                            means you can get to work sooner and finish jobs faster.
                        </p> */}
                    </div>
                </div>
            </div>
            <div className="col-lg-2">
                <div class="card">
                    <div class="card-image">
                        <img src="http://gbdevpress.enerbank.com/wp-content/uploads/2024/05/Boost-close-rate_1.png" class="icon" alt="Improve close rate" />
                    </div>
                    <div class="card-body">
                        <h3>Improve <br/>Close Rate</h3>
                    </div>
                </div>
            </div>
            <div className="col-lg-2">
                <div class="card">
                    <div class="card-image">
                        <img src="http://gbdevpress.enerbank.com/wp-content/uploads/2024/05/Reduce-cancellations_1.png" class="icon" alt="Reducing cancelations" />
                    </div>
                    <div class="card-body">
                        <h3>Reducing Cancelations</h3>
                    </div>
                </div>
            </div>
            <div className="col-lg-2">
                <div class="card">
                    <div class="card-image">
                        <img src="http://gbdevpress.enerbank.com/wp-content/uploads/2024/05/Grow-average-project-size_1.png" class="icon" alt="Getting bigger jobs" />
                    </div>
                    <div class="card-body">
                        <h3>Getting Bigger Jobs</h3>
                        {/* <p class="card-text">
                            Time is of the essence when it comes to an HVAC project.
                            With EnerBank, your customer can receive a lending decision
                            within minutes and only need to sign once upfront. This
                            means you can get to work sooner and finish jobs faster.
                        </p> */}
                    </div>
                </div>
            </div>
            <div className="col-lg-2">
                <div class="card">
                    <div class="card-image">
                        <img src="http://gbdevpress.enerbank.com/wp-content/uploads/2024/05/person-circle-arrows.png" class="icon" alt="Getting more referrals" />
                    </div>
                    <div class="card-body">
                        <h3>Getting More Referrals</h3>
                    </div>
                </div>
            </div>
            <div className="col-lg-2">
                <div class="card">
                    <div class="card-image">
                        <img src="http://gbdevpress.enerbank.com/wp-content/uploads/2024/05/Improve-cash-flow_1.png" class="icon" alt="Improving your cash flow" />
                    </div>
                    <div class="card-body">
                        <h3>Improving Your Cash Flow</h3>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </section>
      <section className="stronger-future bg-teal">
        <div className="bg-teal">
            <h2 className="text-center text-white mb-4">Home improvement lending with Regions works</h2>
        </div>
        <div className="container">
            <div className="text-center text-white">
            <p className="text-white">As specialists in home improvement financing, we&#39;ve developed mutually beneficial relationships with thousands of contractors, dealers, manufacturers, and distributors. Through those relationships, tens of thousands of homeowners have enjoyed repairs and upgrades to their homes. </p>
            </div>
          </div>
        </section>
        <section className="spotlight-offset">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-5">
                        <img src="http://gbdevpress.enerbank.com/wp-content/uploads/2024/05/broad-range-of-financial-products.jpg" className="image" alt="a couple using a laptop"
                    />
                    </div>
                    <div className="col-lg-7 spotlight-band-copy">
                        <h2>A broader range of financial<br/> products</h2>
                        <p>Enhance your business by giving your customers more choices. Explore our <Link classname="eb-link" to="https://www.regions.com/small-business">Small Business</Link> and <Link classname="eb-link" to="https://www.regions.com/commercial-banking">Commercial Banking</Link> resources for a full range of our services.</p>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="col-lg-5">
                        <img src="http://gbdevpress.enerbank.com/wp-content/uploads/2024/05/dedicated-personalized-support.jpg" className="image" alt="a couple using a laptop"
                    />
                    </div>
                    <div className="col-lg-7 spotlight-band-copy">
                        <h2>A dedicated team for personalized support</h2>
                        <p>We're ranked #1 in online banking and customer satisfaction by JD Power, making a positive difference in for you and your customers. Check out our <Link classname="eb-link" to="https://www.regions.com/promo/awards">awards</Link>.</p>
                    </div>
                </div>
            </div>
        </section>
        <section className="qa-accordion bg-light-gray">
            <div className="container">
                <div className="col-md-12 mb-5">
                    <h2 className="text-center mb-4">Q & A for contractors</h2>
                    <p>As we complete our transition to Regions Home Improvement Financing, here are a few questions and answers to help you better understand our brand update</p>
                </div>
            <div className="row">
                <div className="col-md-12">
                    <Tabbordion
                        blockElements={blockElements}
                        animateContent={"height"}
                        mode={"toggle"}
                        className="regions-accordion"
                        name="accordion"
                    >
                        <TabPanel>
                            <TabLabel className="btn btn-link">
                                <div className="row-fluid">
                                    <div className="col-11">
                                        <span className="title-link">What&#39;s in it for me?</span>
                                    </div>
                                    <div className="col-1">
                                        <span className="accordion-icon"></span>
                                    </div>
                                </div>
                            </TabLabel>
                            <TabContent>
                                <p>Plenty of good things! With EnerBank&#39;s proven platform and skilled financial professionals, Regions Home Improvement Financing will continue to help you blah blah is ideally positioned to help us deepen relationships with clients while reaching new customers with convenient home improvement lending options.</p>
                            </TabContent>
                        </TabPanel>
                        <TabPanel>
                            <TabLabel className="btn btn-link">
                                <div className="row-fluid">
                                    <div className="col-11">
                                        <span className="title-link">Will I need to do anything differently?</span>
                                    </div>
                                    <div className="col-1">
                                        <span className="accordion-icon"></span>
                                    </div>          
                                </div>
                            </TabLabel>
                            <TabContent>
                                <p>No. As we retire the EnerBank brand, a few things might look a little different, but the application, funding, and disbursement processes will remain as convenient as ever for both you and your customers.
                                </p>
                            </TabContent>
                        </TabPanel>
                        <TabPanel>
                            <TabLabel className="btn btn-link">
                                <div className="row-fluid">
                                    <div className="col-11">
                                        <span className="title-link">What about my customers — will our relationship change?</span>
                                    </div>
                                    <div className="col-1">
                                        <span className="accordion-icon"></span>
                                    </div>
                                </div>
                            </TabLabel>
                            <TabContent>
                                <p>Our name change won&#39;t alter your customer&#39;s loan terms or repayment schedule. We&#39;ll still provide homeowners with the excellent service and support they&#39;re used to.</p>
                            </TabContent>
                        </TabPanel>
                        <TabPanel>
                            <TabLabel className="btn btn-link">
                                <div className="row-fluid">
                                    <div className="col-11">
                                        <span className="title-link">If I need additional information about Regions, who can I contact?</span>
                                    </div>
                                    <div className="col-1">
                                        <span className="accordion-icon"></span>
                                    </div>
                                </div>
                            </TabLabel>
                            <TabContent>
                                <p>Please visit <Link classname="eb-link" to="https://www.regions.com">regions.com</Link> or <Link classname="eb-link" to="https://www.regions.com/locator">stop by a convenient branch in our 15-state footprint</Link>.</p>
                            </TabContent>
                        </TabPanel>
                        <TabPanel>
                            <TabLabel className=" btn btn-link">
                                <div className="row-fluid">
                                    <div className="col-11">
                                        <span className="title-link">I've got more questions. Who can I talk to?</span>
                                    </div>
                                    <div className="col-1">
                                        <span className="accordion-icon"></span>
                                    </div>
                                </div>
                            </TabLabel>
                            <TabContent>
                                <p>Please contact your relationship manager or call{" "}
                                    <a href="tel:888.390.1220">888.390.1220</a>, option 2</p>
                            </TabContent>
                        </TabPanel>
                    </Tabbordion>
                </div>
            </div>
            </div>
      </section>
    </Layout>
    )
}
export default RegionsContractorPage
